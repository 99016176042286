import ISystemUpdates from '../../../models/SystemUpdate/update'
import { CargoResponse } from '../../../models/User/user'
import companyPersistanceContent from './01-companyPersistance-update'
import clientInfoVisualizationContent from './02-clientInfoVisualization-update'
import partnerInfoVisualizationContent from './03-partnerInfoVisualization-update'
import allPartnersDocOptionContent from './04-allPartnersDocOption-update'
import bugFixAndOtimizationContent from './05-bugFixAndOtimization-update'

const reciclaEmployeesOnly = [
  'Administrador',
  'Tecnologia',
  'Sucesso do Cliente',
  'Experiência do Cliente',
  'Analista de Dados',
]

const systemUpdatesInfos: ISystemUpdates[] = [
  {
    identifier: '20240828at05',
    title: 'Otimização e reparação do sistema para a melhor experiência do usuário.',
    description: 'Nesta primeira otimização, trazemos melhorias de usabilidade e correções de bugs.',
    changesMade: [
      'Tornamos os campos de veículo e motorista, do formulário de Novo MTR, facultativos e de valores livres.',
      'Disponibilizamos a criação e visualização de observações (sobre clientes) para mais tipos de usuários.',
      'Corrigimos as instabilidades na criação de Modelos de MTR.',
      'Melhoramos a visualização do Monitor Verde e seu filtro de seleção de empresas.',
      'Melhoramos a visibilidade de campos obrigatórios não preenchidos no formulário de novo Cliente',
    ],
    date: '28 de agosto de 2024',
    content: bugFixAndOtimizationContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20240820at04',
    title: 'Visualização ágil de documentação de parceiros',
    description: 'Com um clique você pode ver a documentação de todos os parceiros de uma só vez.',
    changesMade: [
      'Disponibiliza a opção "Todos os parceiros" na página "Documentação Ambiental". Tal opção disponibiliza uma listagem completa de todos os parceiros que atendem as empresas vinculadas a sua conta.',
    ],
    date: '20 de agosto de 2024',
    content: allPartnersDocOptionContent,
    allowedRoles: ['Acesso Básico'],
  },
  {
    identifier: '20240812at02',
    title: 'Visualização de dados dos Parceiros',
    description: 'É possível visualizar as informações do Parceiro com um clique.',
    changesMade: [
      'Disponibiliza a visualização detalhada de dados cadastrados na própria listagem de parceiros.',
      'Facilita o acesso a edição do parceiro selecionado.',
    ],
    date: '12 de agosto de 2024',
    content: partnerInfoVisualizationContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20240809at02',
    title: 'Visualização de dados dos Clientes',
    description: 'Agora é possível visualizar as informações do Cliente com um clique.',
    changesMade: [
      'Adiciona uma visualização dinâmica e detalhada de cada empresa, na página de listagem de clientes.',
      'O acesso a edição do cliente selecionado foi facilitado através de um botão de edição na caixa de informações aberta.',
    ],
    date: '09 de agosto de 2024',
    content: clientInfoVisualizationContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20240725at01',
    title: 'Agilidade na navegação',
    description: 'Selecione a unidade almejada e navegue entre as páginas sem perder a seleção.',
    changesMade: [
      'Persistência de uma empresa selecionada ao navegar entre as demais páginas, seja de listagem ou formulários.',
    ],
    date: '25 de julho de 2024',
    content: companyPersistanceContent,
    allowedRoles: ['Acesso Básico'],
  },
]

export const getUpdatesByUserPermissions = (userCargos: CargoResponse[]): ISystemUpdates[] => {
  const cargoNames = userCargos.map((cargo) => cargo.name)
  if (cargoNames.includes('Administrador')) return systemUpdatesInfos.slice(0, 5)

  return systemUpdatesInfos
    .filter((update) => cargoNames.some((cargo) => update.allowedRoles.includes(cargo)))
    .slice(0, 5)
}

export const getLatestUpdate = (userCargos: CargoResponse[]): ISystemUpdates => {
  return getUpdatesByUserPermissions(userCargos)[0]
}

export default systemUpdatesInfos
