import React from 'react'
import { useParams } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { ClientFormType } from '../../../../models/Company/Company'
import ClientFinancial from './components/ClientFinancial'

interface ClientFinancialTabProps {
  form: UseFormReturn<ClientFormType>
}

const ClientFinancialTab: React.FC<ClientFinancialTabProps> = ({ form }) => {
  const { identifier } = useParams<{ identifier: string }>()
  return <ClientFinancial form={form} identifier={identifier as string} editable />
}

export default ClientFinancialTab
