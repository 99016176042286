import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Grid, Typography } from '@mui/material'
import AppContext, { AppContextType } from '../../AppContext'
import { defaultBreadCrumbItems } from '../../components/BreadCrumb/BreadCrumb'
import brokenRobot from '../../assets/images/BrokenRobot.png'
import useStyles from './SystemUpdatesStyle'
import ISystemUpdates from '../../models/SystemUpdate/update'
import systemUpdatesInfos from './Updates/systemUpdates'
import { getLoggedUser } from '../../services/Auth/TokenService'

const SystemUpdates: React.FC = () => {
  const { setTitle, setCustomHeaderContent, setItemsBreadCrumb, showAlert } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const { identifier } = useParams<{ identifier: string }>()
  const [systemUpdate, setSystemUpdate] = React.useState<ISystemUpdates | null>(null)
  const classes = useStyles()
  const navigate = useNavigate()

  const checkUserPermission = (selectedUpdate: ISystemUpdates): void => {
    const userCargos = getLoggedUser()?.cargos || []
    const isUserAllowed = userCargos.some((cargo) => selectedUpdate.allowedRoles.includes(cargo.name))
    if (!isUserAllowed) navigate('/main/access-denied')
  }

  const findUpdate = (): void => {
    const selectedUpdate = systemUpdatesInfos.find((update) => update.identifier === identifier)
    if (selectedUpdate) checkUserPermission(selectedUpdate)
    setSystemUpdate(selectedUpdate || null)
  }

  React.useEffect(() => {
    findUpdate()
    setTitle('Ver Atualização')
    setCustomHeaderContent(<div />)
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Ver atualização', path: `/main/system-updates/${identifier}` },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    findUpdate()
  }, [identifier])

  return (
    <div className={classes.mainContainer}>
      <Grid container>
        <Grid item xs={12}>
          <Card className="card-filters" variant="outlined">
            {systemUpdate ? (
              <div>
                <div className={classes.updateHeader}>
                  <h2 data-cy="update-title" className={classes.titleStyle}>
                    {systemUpdate.title}
                  </h2>
                  <Typography variant="caption" data-cy="update-date">
                    {systemUpdate.date}
                  </Typography>
                </div>
                {systemUpdate.content}
              </div>
            ) : (
              <div className={classes.noUpdate}>
                <img src={brokenRobot} alt="Robô quebrado" />
                <h3 style={{ textAlign: 'center' }}>Atualização indisponível</h3>
              </div>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default SystemUpdates
