import React from 'react'
import { Grid, List, ListItem, Typography } from '@mui/material'
import vehicleAndDriverFields from '../../../assets/images/updates/bugFix-and-otimization-update/vehicleAndDriverFields.png'
import observations from '../../../assets/images/updates/bugFix-and-otimization-update/observations.png'
import mtrModel from '../../../assets/images/updates/bugFix-and-otimization-update/mtr-model.png'
import greenMonitorFilter from '../../../assets/images/updates/bugFix-and-otimization-update/green-monitor-filter.png'
import clientForm from '../../../assets/images/updates/bugFix-and-otimization-update/client-form.gif'

const flexStyles = { display: 'flex', flexDirection: 'column', paddingTop: '1rem' }
const imgContainerFlexStyles = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '1rem',
}

const bugFixAndOtimizationContent = (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="body1" data-cy="update-content">
        Na busca constante de melhorar o sistema para os nossos usuários, realizamos otimizações e correções de bugs de
        acordo com feedbacks do usuário. Confira abaixo as melhorias e correções realizadas:
      </Typography>
    </Grid>
    <List>
      <ListItem sx={flexStyles}>
        <Grid item xs={12}>
          <Typography variant="body1" data-cy="update-content">
            <strong>(1)</strong> Campos de veículo e motorista, do formulário de Novo MTR, podem ser preenchidos
            livremente sem a necesssidade de cadastro prévio. Além disso os campos se tornaram facultativos, não sendo
            mais um impeditivo para a gerar um MTR.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={imgContainerFlexStyles}>
          <img
            src={vehicleAndDriverFields}
            alt="Visualização dos campos de veículo e motorista com valores livres."
            style={{ width: '90%' }}
          />
          <div>
            <Typography variant="caption">
              Visualização dos campos de veículo e motorista com valores livres.
            </Typography>
          </div>
        </Grid>
      </ListItem>
      <ListItem sx={flexStyles}>
        <Grid item xs={12}>
          <Typography variant="body1" data-cy="update-content">
            <strong>(2)</strong> Disponibilizamos o uso das observações para mais tipos de usuários dentro do controle
            de acessos do sistema. Agora, cabe apenas ao gestor de cada área indicar quem pode visualizar e criar as
            observações, de acordo com cada cargo.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={imgContainerFlexStyles}>
          <img src={observations} alt="Visualização da aba de observações." style={{ width: '90%' }} />
          <div>
            <Typography variant="caption">Visualização da aba de observações.</Typography>
          </div>
        </Grid>
      </ListItem>
      <ListItem sx={flexStyles}>
        <Grid item xs={12}>
          <Typography variant="body1" data-cy="update-content">
            <strong>(3)</strong> Corrigimos e adaptamos detalhes da criação de modelos de MTR para possibilitar e
            melhorar seu uso.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={imgContainerFlexStyles}>
          <img src={mtrModel} alt="Visualização do formulário de Novo Modelo de MTR" style={{ width: '90%' }} />
          <div>
            <Typography variant="caption">Visualização do formulário de Novo Modelo de MTR</Typography>
          </div>
        </Grid>
      </ListItem>
      <ListItem sx={flexStyles}>
        <Grid item xs={12}>
          <Typography variant="body1" data-cy="update-content">
            <strong>(4)</strong> Melhoramos a visualização do Monitor Verde e seu filtro de seleção de empresas.
            Removemos espaços em branco desnecessários e reposicionamos o filtro para melhor usabilidade do usuário na
            exploração do relatório.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={imgContainerFlexStyles}>
          <img
            src={greenMonitorFilter}
            alt="Visualização do novo cabeçalho do Monitor Verde"
            style={{ width: '90%' }}
          />
          <div>
            <Typography variant="caption">Visualização do novo cabeçalho do Monitor Verde.</Typography>
          </div>
        </Grid>
      </ListItem>
      <ListItem sx={flexStyles}>
        <Grid item xs={12}>
          <Typography variant="body1" data-cy="update-content">
            <strong>(5)</strong> Além de indicar os campos faltantes ou com valores irregulares, o formulário de novo
            Cliente agora conduz o usuário até a aba onde é necessária a correção. Junto a um alerta, esta mudança busca
            tornar o processo de inserção de novos clientes ainda mais compreensível e amigável.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={imgContainerFlexStyles}>
          <img src={clientForm} alt="Visualização do formulário de Novo Cliente" style={{ width: '90%' }} />
          <div>
            <Typography variant="caption">Visualização do formulário de Novo Cliente</Typography>
          </div>
        </Grid>
      </ListItem>
    </List>
  </Grid>
)

export default bugFixAndOtimizationContent
