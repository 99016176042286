import React from 'react'
import { useNavigate } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Card, Box, Tab, Grid, Button } from '@mui/material'

import AppContext, { AppContextType } from '../../../AppContext'
import { PartnerFormType } from '../../../models/Company/Company'

import PartnerPrimaryInfoTab from '../../../components/CompanyForm/PartnerTabs/PrimaryInfo/PartnerPrimaryInfoTab'
import PartnerTechnicalInfoTab from '../../../components/CompanyForm/PartnerTabs/TechnicalInfo/PartnerTechnicalInfoTab'
import PartnerContactTab from '../../../components/CompanyForm/PartnerTabs/Contact/PartnerContactTab'
import PartnersNotesTab from '../../../components/CompanyForm/PartnerTabs/Notes/PartnerNotesTab'
import PartnerDocumentsTab from '../../../components/CompanyForm/PartnerTabs/Documents/PartnerDocumentsTab'

import { useStyles } from './PartnerFormStyle'

interface PartnersFormProps {
  form: UseFormReturn<PartnerFormType>
  onSubmit: (data: PartnerFormType) => void
  id?: string | number
}

const PartnersForm: React.FC<PartnersFormProps> = ({ form, onSubmit, id }) => {
  const [tabValue, setTabValue] = React.useState<string>('Dados Primários')
  const partnerFormClasses = useStyles()

  const navigate = useNavigate()
  const { handleSubmit, trigger } = form
  const { showAlert } = React.useContext<AppContextType>(AppContext as React.Context<AppContextType>)

  const handleTabValueChange = (event: React.SyntheticEvent, newValue: string): void => {
    setTabValue(newValue)
  }

  const isFormValid = async (): Promise<boolean> => {
    const isValid = await trigger()
    return Promise.resolve(isValid)
  }

  const submitForm: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    const isValid = await isFormValid()
    if (!isValid) {
      setTabValue('Dados Primários')
      showAlert('warning', 'Preencha todos os campos obrigatórios')
      return
    }
    handleSubmit(onSubmit)()
  }

  return (
    <Card className={`${partnerFormClasses.mainCard} form-card`} variant="outlined">
      <form encType="multipart/form-data" onSubmit={submitForm}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabValueChange} aria-label="lab API tabs example">
                <Tab label="Dados Primários" value="Dados Primários" />
                <Tab label="Dados Técnicos" value="Dados Técnicos" />
                <Tab label="Contatos" value="Contatos" />
                {id && <Tab label="Documentação" value="Documentação" />}
                {/* {id && <Tab label="Observações" value="Observações" />} */}
              </TabList>
            </Box>
            <TabPanel
              value="Dados Primários"
              sx={{
                paddingLeft: '1px',
                paddingTop: 0,
              }}
            >
              <PartnerPrimaryInfoTab form={form} />
            </TabPanel>
            <TabPanel
              value="Dados Técnicos"
              sx={{
                paddingLeft: '1px',
                paddingTop: 0,
              }}
            >
              <PartnerTechnicalInfoTab form={form} />
            </TabPanel>
            <TabPanel
              value="Contatos"
              sx={{
                paddingLeft: '1px',
                paddingTop: 0,
              }}
            >
              <PartnerContactTab form={form} />
            </TabPanel>
            <TabPanel
              value="Documentação"
              sx={{
                paddingLeft: '1px',
                paddingTop: 0,
              }}
            >
              <PartnerDocumentsTab form={form} />
            </TabPanel>
            {/* <TabPanel
              value="Observações"
              sx={{
                paddingLeft: '1px',
                paddingTop: 0,
              }}
            >
              <PartnersNotesTab form={form} />
            </TabPanel> */}
          </TabContext>
        </Box>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '24px',
            marginBottom: '50px',
            marginRight: '30px',
          }}
        >
          <Button
            variant="outlined"
            type="button"
            sx={{
              width: '220px',
              height: '40px',
              borderRadius: '40px',
              marginRight: '10px',
            }}
            onAbort={() => navigate('/main/partners/list')}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            data-cy="submit-new-partner-button"
            color="primary"
            type="submit"
            sx={{
              width: '220px',
              height: '40px',
              borderRadius: '40px',
              marginLeft: '10px',
            }}
          >
            Salvar
          </Button>
        </Grid>
      </form>
    </Card>
  )
}

export default PartnersForm
