import React from 'react'
import IconButton from '@mui/material/IconButton'
import { Card } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import AppContext, { AppContextType } from '../../../AppContext'
import DataTable from '../../../components/DataTable/DataTable'
import User, { UserMinimalResponse, UsersList } from '../../../models/User/user'

import { getUsersByCustomerIdentifier, updateUserCargo } from '../../../services/User/UserService'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import Icons from '../../../assets/icons/svgIcons'
import EditDeletePopover from '../../../components/PopOver/EditDeletePopover'
import { featureAccessCheck, iconAccessCheck } from '../../../utils/AccessPermissionsCheck'
import { acessLevel } from '../../../models/Setting/Settings'
import { getAllAccessLevels } from '../../../services/Settings/acessLevelService'
import { ApplicationColors } from '../../../utils/applicationColors'
import InputSelect2Multiple from '../../../components/Form/Select/Select2Multiple'
import { getAllCustomers } from '../../../services/Companies/CompaniesService'
import { CompanyMinimalInfo } from '../../../models/Company/Company'
import getInitialSelectedCompany, { getCompanyByIdentifier } from '../../../utils/getInitialSelectedCompany'

const UserList: React.FC = () => {
  const { setTitle, setCustomHeaderContent, showAlert, setItemsBreadCrumb, defaultCompany, setDefaultCompany } =
    React.useContext(AppContext as React.Context<AppContextType>)
  const [customers, setCustomers] = React.useState<CompanyMinimalInfo[]>([])
  const [customersMap, setCustomersMap] = React.useState<{ value: string; label: string }[]>([])
  const [users, setUsers] = React.useState<UserMinimalResponse[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [acessLevels, setAccessLevels] = React.useState<acessLevel[]>([])
  const [acessLevelsSelect, setAccessLevelsSelect] = React.useState<{ value: number; label: string }[]>([])
  const [selectedCompany, setSelectedCompany] = React.useState<string>('')
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [itemToChange, setItemToChange] = React.useState<User>()
  const [anchor, setAnchor] = React.useState<any>(null)
  const navigate = useNavigate()
  const usersMap = [{ key: 'username', value: 'Nome de Usuário' }]

  const isBasicAccessSelected = (accessIdList: number[]): boolean => {
    return accessIdList.includes(2)
  }

  const setCargosList = (userInfo: UserMinimalResponse): number[] => {
    const cargoList = userInfo.cargos?.map((cargo) => cargo.id)
    if (isBasicAccessSelected(cargoList)) {
      return cargoList
    }
    cargoList.push(2)
    return cargoList
  }

  const onChangeAccessLevel = (v: Select2Options[] | null, userIdentifier: string): void => {
    setLoading(true)
    const idAccessLevel = v?.map((al) => al.value) as number[]

    if (!isBasicAccessSelected(idAccessLevel)) {
      showAlert('warning', 'Não é possível remover o Acesso Básico. Contate o suporte.')
      setLoading(false)
      return
    }

    updateUserCargo(idAccessLevel, userIdentifier)
      .then(() => {
        showAlert('success', 'Nivel de acesso do usuário atualizado com sucesso.')
        getUsersByCustomerIdentifier(selectedCompany)
          .then((data) => {
            setUsers(data || [])
          })
          .catch(() => {
            showAlert('error', 'Erro ao carregar Usuários.')
          })
      })
      .catch(() => {
        showAlert('error', 'Erro ao atualizar nivel de acesso do usuário.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleClickNew = React.useCallback((): void => {
    navigate('/main/user/form')
  }, [navigate])

  const handleEdit = (item: UsersList): void => {
    navigate(`/main/user/form/${item.identifier}`)
  }

  const customHeaderContent: JSX.Element = React.useMemo(
    () =>
      featureAccessCheck('user-write') && (
        <IconButton aria-label="Novo usuário" data-cy="go-to-userform-button" onClick={handleClickNew} size="large">
          <Icons.Add fill="white" />
        </IconButton>
      ),
    [handleClickNew]
  ) as JSX.Element

  const handleUsersByCustomer = (companyIdentifier: string): void => {
    getUsersByCustomerIdentifier(companyIdentifier)
      .then((data) => {
        setUsers(data || [])
      })
      .catch(() => {
        showAlert('error', 'Erro ao carregar Usuários.')
      })
  }

  const setCustomersInfo = (customersInfo: CompanyMinimalInfo[]): void => {
    setCustomers(customersInfo)
    setCustomersMap(
      customersInfo.map((companyData) => {
        return { label: `${companyData.name}`, value: companyData.identifier }
      })
    )
  }

  const handleCustomersInfo = (): void => {
    getAllCustomers()
      .then((customersInfo) => {
        setCustomersInfo(customersInfo)
        const initialSelectedCustomer = getInitialSelectedCompany(customersInfo, defaultCompany)
        const companyIdentifier = initialSelectedCustomer.identifier
        setSelectedCompany(companyIdentifier)
        handleUsersByCustomer(companyIdentifier)
      })
      .catch(() => {
        showAlert('error', 'Erro ao carregar empresas.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleAccessLevels = (): void => {
    getAllAccessLevels()
      .then((data) => {
        setAccessLevels(data)
        const accessLevelsData = data.map((AL) => {
          return {
            value: AL.idCargo,
            label: AL.name,
          }
        })
        setAccessLevelsSelect(accessLevelsData)
      })
      .catch(() => {
        showAlert('error', 'Erro ao carregar niveis de acesso.')
      })
  }

  const handleInit = React.useCallback((): void => {
    setLoading(true)
    setTitle('Listar Usuários')
    setCustomHeaderContent(customHeaderContent)

    handleCustomersInfo()
    handleAccessLevels()
  }, [customHeaderContent, setCustomHeaderContent, setTitle, showAlert])

  const handleMoreOptions = (item: User, index?: number, anchorEl?: EventTarget & HTMLButtonElement): void => {
    setIsOpen(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  const handleClose = (): void => {
    setIsOpen(false)
  }

  const onChangeCompany = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    setLoading(true)
    const companyIdentifier = value?.value as string
    setSelectedCompany(companyIdentifier)
    handleUsersByCustomer(companyIdentifier)
    const selectedCustomer = getCompanyByIdentifier(customers, companyIdentifier)
    setDefaultCompany(selectedCustomer)
    setLoading(false)
  }

  React.useEffect(() => {
    handleInit()
  }, [handleInit])

  React.useEffect(() => {
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Listar usuários', path: '/main/users/list' }])
  }, [])

  return (
    <>
      {featureAccessCheck('user-filter') && (
        <Card className="card-filters" variant="outlined">
          <InputSelect2
            id="companyFilter"
            label="Empresa"
            value={selectedCompany}
            options={customersMap}
            onChange={onChangeCompany}
          />
        </Card>
      )}
      <div className={featureAccessCheck('user-filter') ? 'filter--open' : 'filter--closed'}>
        <DataTable
          data={users}
          dataMap={usersMap}
          loading={loading}
          actions={
            iconAccessCheck('user-edit')
              ? [
                  {
                    label: 'Opções',
                    icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />,
                    fn: handleMoreOptions,
                  },
                ]
              : []
          }
          customColumns={
            featureAccessCheck('user-edit')
              ? [
                  {
                    label: 'Nível de acesso',
                    content: (item: UserMinimalResponse) => (
                      <InputSelect2Multiple
                        id="selectAcessLevel"
                        label=""
                        value={setCargosList(item)}
                        onChange={(e, v) => onChangeAccessLevel(v, item.identifier)}
                        options={acessLevelsSelect}
                      />
                    ),
                  },
                ]
              : undefined
          }
        />
        <EditDeletePopover
          editFunction={iconAccessCheck('user-edit') ? handleEdit : undefined}
          open={isOpen}
          item={itemToChange}
          handleClose={handleClose}
          anchor={anchor}
        />
      </div>
    </>
  )
}

export default UserList
