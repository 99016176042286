import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Card, Box, Tab, Grid, Button } from '@mui/material'

import { ClientFormType } from '../../../models/Company/Company'

import ClientPrimaryInfoTab from '../../../components/CompanyForm/ClientTabs/PrimaryInfo/ClientPrimaryInfoTab'
import ClientFinancialTab from '../../../components/CompanyForm/ClientTabs/Financial/ClientFinancialTab'
import ClientContractsTab from '../../../components/CompanyForm/ClientTabs/Contract/ClientContractTab'
import ClientContactsTab from '../../../components/CompanyForm/ClientTabs/Contacts/components/ClientContactsTabs'
import ClientNotesTab from '../../../components/CompanyForm/ClientTabs/Notes/ClientNotesTab'
import { useStyles } from './ClientStyle'
import getIncompleteTab from './utils/mandatoryFields'
import AppContext, { AppContextType } from '../../../AppContext'

interface ClientFormProps {
  form: UseFormReturn<ClientFormType>
  onSubmit: (data: ClientFormType) => void
  identifier?: string
}

const ClientForm: React.FC<ClientFormProps> = ({ form, onSubmit, identifier }) => {
  const [tabValue, setTabValue] = React.useState<string>('Dados Primários')
  const { showAlert } = React.useContext<AppContextType>(AppContext as React.Context<AppContextType>)
  const clientFormStyleClasses = useStyles()

  const navigate = useNavigate()
  const { handleSubmit, trigger } = form

  const handleTabValueChange = (event: React.SyntheticEvent, newValue: string): void => {
    setTabValue(newValue)
  }

  const isFormValid = async (): Promise<boolean> => {
    const isValid = await trigger()
    return Promise.resolve(isValid)
  }

  const submitForm: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    const isValid = await isFormValid()
    if (!isValid) {
      const unfilledFields = form.formState.errors
      const newTabValue = getIncompleteTab(unfilledFields as unknown as ClientFormType)
      setTabValue(newTabValue)
      showAlert('warning', 'Preencha todos os campos obrigatórios')
      return
    }
    handleSubmit(onSubmit)()
  }

  return (
    <Card className={`${clientFormStyleClasses.mainCard} form-card`} variant="outlined">
      <form encType="multipart/form-data" onSubmit={submitForm}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabValueChange} aria-label="lab API tabs example">
                <Tab label="Dados Primários" value="Dados Primários" data-cy="client-primary-data-tab" />
                <Tab label="Dados do contrato" value="Dados do contrato" data-cy="client-contract-data-tab" />
                {identifier && <Tab label="Financeiro" value="Financeiro" data-cy="client-finance-tab" />}
                <Tab label="Contatos" value="Contatos" data-cy="client-contacts-tab" />
                {identifier && <Tab label="Observações" value="Observações" data-cy="client-observations-tab" />}
              </TabList>
            </Box>
            <TabPanel
              value="Dados Primários"
              sx={{
                paddingLeft: '1px',
                paddingTop: 0,
              }}
            >
              <ClientPrimaryInfoTab form={form} />
            </TabPanel>
            <TabPanel
              value="Dados do contrato"
              sx={{
                paddingLeft: '1px',
                paddingTop: 0,
              }}
            >
              <ClientContractsTab form={form} />
            </TabPanel>
            <TabPanel
              value="Financeiro"
              sx={{
                paddingLeft: '1px',
                paddingTop: 0,
              }}
            >
              <ClientFinancialTab form={form} />
            </TabPanel>
            <TabPanel
              value="Contatos"
              sx={{
                paddingLeft: '1px',
                paddingTop: 0,
              }}
            >
              <ClientContactsTab form={form} />
            </TabPanel>
            <TabPanel
              value="Observações"
              sx={{
                paddingLeft: '1px',
                paddingTop: 0,
              }}
            >
              <ClientNotesTab form={form} />
            </TabPanel>
          </TabContext>
        </Box>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '24px',
            marginBottom: '50px',
            marginRight: '30px',
          }}
        >
          <Button
            variant="outlined"
            type="button"
            sx={{
              width: '220px',
              height: '40px',
              borderRadius: '40px',
              marginRight: '10px',
            }}
            onClick={() => navigate('/main/clients/list')}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            data-cy="submit-new-client-button"
            sx={{
              width: '220px',
              height: '40px',
              borderRadius: '40px',
              marginLeft: '10px',
            }}
          >
            Salvar
          </Button>
        </Grid>
      </form>
    </Card>
  )
}

export default ClientForm
